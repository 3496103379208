import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import { ActionsImpl } from 'direct-vuex'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { UpwardPlayerRecognitionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPlayerRecognitionTypeID'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'

interface UpwardTypesState {
  leagueTypes: UpwardLeagueTypeID[]
  programTypes: UpwardProgramTypeID[]
  playerRecognitionTypes: UpwardPlayerRecognitionTypeID[]
  positionTypes: UpwardPositionTypeID[]
  currentLeagueType: UpwardLeagueTypeID | null
  currentProgramType: UpwardProgramTypeID | null
  gradeTypes: UpwardGradeTypeID[]
}

const upwardTypesState: UpwardTypesState = {
  leagueTypes: [] as UpwardLeagueTypeID[],
  programTypes: [] as UpwardProgramTypeID[],
  playerRecognitionTypes: [] as UpwardPlayerRecognitionTypeID[],
  positionTypes: [] as UpwardPositionTypeID[],
  currentLeagueType: null,
  currentProgramType: null,
  gradeTypes: [] as UpwardGradeTypeID[],
}

export enum getterNames {
  leagueTypes = 'leagueTypes',
  programTypes = 'programTypes',
  recognitionTypes = 'playerRecognitions',
  positionTypes = 'positionTypes',
  currentLeagueType = 'currentLeagueType',
  currentProgramType = 'currentProgramType',
  gradeTypes = 'gradeTypes',
}

const getterTree: GetterTree<UpwardTypesState, RootState> = {
  [getterNames.leagueTypes]: (state) => state.leagueTypes,
  [getterNames.programTypes]: (state) => state.programTypes,
  [getterNames.recognitionTypes]: (state) => state.playerRecognitionTypes,
  [getterNames.positionTypes]: (state) => state.positionTypes,
  [getterNames.currentLeagueType]: (state) => state.currentLeagueType,
  [getterNames.currentProgramType]: (state) => state.currentProgramType,
  [getterNames.gradeTypes]: (state) => state.gradeTypes,
}

export enum mutationNames {
  setleagueTypes = 'setLeagueTypes',
  setProgramTypes = 'setProgramTypes',
  setRecognitionTypes = 'setRecognitionTypes',
  setPositionTypes = 'setPositionTypes',
  setCurrentLeagueType = 'setCurrentLeagueType',
  setCurrentProgramType = 'setCurrentProgramType',
  setGradeTypes = 'setGradeTypes',
}

const mutations: MutationTree<UpwardTypesState> = {
  [mutationNames.setleagueTypes](state, { items }) {
    state.leagueTypes = items
  },
  [mutationNames.setProgramTypes](state, { items }) {
    state.programTypes = items
  },
  [mutationNames.setRecognitionTypes](state, { items }) {
    state.playerRecognitionTypes = items
  },
  [mutationNames.setPositionTypes](state, { items }) {
    state.positionTypes = items
  },
  [mutationNames.setCurrentLeagueType](state, { item }) {
    state.currentLeagueType = item
  },
  [mutationNames.setCurrentProgramType](state, { item }) {
    state.currentProgramType = item
  },
  [mutationNames.setGradeTypes](state, { items }) {
    state.gradeTypes = items
  },
}

export enum actionNames {
  fetchLeagueTypes = 'fetchLeagueTypes',
  fetchProgramTypes = 'fetchProgramTypes',
  fetchRecognitionTypes = 'fetchRecognitionTypes',
  fetchPositionTypes = 'fetchPositionTypes',
  fetchGradeTypes = 'fetchGradeTypes',
}

const actions: ActionTree<UpwardTypesState, RootState> & ActionsImpl = {
  async [actionNames.fetchLeagueTypes]({ commit, getters }, { force = false }): Promise<boolean> {
    if (getters[getterNames.leagueTypes].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveLeagueTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setleagueTypes, { items })
      return true
    }

    return false
  },
  async [actionNames.fetchRecognitionTypes](
    { commit, getters },
    { program, force = false }: { program: string; force: boolean }
  ): Promise<boolean> {
    if (getters[getterNames.recognitionTypes].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveRecognitionTypes(program)

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setRecognitionTypes, { items })
      return true
    }

    return false
  },

  async [actionNames.fetchProgramTypes]({ commit, getters }, { force = false }): Promise<boolean> {
    if (getters[getterNames.programTypes].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveProgramTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setProgramTypes, { items })
      return true
    }

    return false
  },

  async [actionNames.fetchPositionTypes]({ commit, getters }, { force = false }): Promise<boolean> {
    if (getters[getterNames.positionTypes].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrievePositionTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setPositionTypes, { items })
      return true
    }

    return false
  },
  async [actionNames.fetchGradeTypes]({ commit, getters }, { force = false }): Promise<boolean> {
    if (getters[getterNames.gradeTypes].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveGradeTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setGradeTypes, { items })
      return true
    }

    return false
  },
}

export const namespace = 'upwardTypes'

export const upwardTypes = {
  namespaced: true as true,
  state: upwardTypesState,
  getters: getterTree,
  actions,
  mutations,
}
