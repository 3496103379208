
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { cloneDeep, differenceWith } from 'lodash'

import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { rankPlayers } from '@/common/players'
import { gameFormatToPlayersPerSeg } from '@/common/PlayersPerSegmentConversion'
import { CurrentGameLineupWithRotationMixin } from '@/common/CurrentGameLineupWithRotationMixin'
import { CurrentGameLineupCommonMixin } from '@/common/CurrentGameLineupCommonMixin'
import { isFootball, isBaseballOrSoftball, ProgramEnum } from '@/common/programEnum'

import { generateLineupWithRotation, getRound, getIsScheduled } from '@/services/lineupService'

import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import Loading from '@/elements/Loading.vue'
import SimpleMenu from '@/elements/SimpleMenu.vue'

import StartingSideToggle from '@/components/StartingSideToggle.vue'
import PageStateToggle from '@/components/PageStateToggle.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import GameDayLineup from '@/components/GameDayLineup.vue'
import ListPaging from '@/components/ListPaging.vue'

import * as lineupStore from '@/store/lineup'
import * as teamStore from '@/store/team'
import * as upwardTypesStore from '@/store/upwardTypes'
import * as programStore from '@/store/programs'

import teamClient from '@/clients/teamClient'

import {
  GameLineupWithRotation,
  GameLineupPositionPlayer,
  SidesEnum,
  SubstitutionPreference,
  SubstitutionPreferenceImpl,
  SubstitutionType,
} from '@/models/Lineup'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import RegenerateLineup from '@/components/RegenerateLineup.vue'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import addDays from '@/utils/addDays'

@Component({
  components: {
    Breadcrumbs,
    GameDayLineup,
    ListPaging,
    StartingSideToggle,
    PageStateToggle,
    Loading,
    ConfirmationModal,
    RegenerateLineup,
    SimpleMenu,
  },
})
export default class LineupWithRotation extends Mixins(
  CurrentGameLineupWithRotationMixin,
  CurrentGameLineupCommonMixin
) {
  @Getter(teamStore.getterNames.hasScheduledGames, { namespace: teamStore.namespace })
  private readonly hasScheduledGames!: boolean

  @Getter(teamStore.getterNames.currentTeamNumberOfSegments, { namespace: teamStore.namespace })
  private readonly currentTeamNumberOfSegments!: number

  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Action(teamStore.actionNames.fetchAndSetCurrentTeam, { namespace: teamStore.namespace })
  private fetchAndSetCurrentTeam!: ({
    upwId,
    programTypeId,
    divisionId,
    teamId,
  }: {
    upwId: string
    programTypeId: string
    divisionId: number
    teamId: number
  }) => Promise<boolean>

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  readonly currentProgram!: LeagueInfoCondensed

  private loadingRecognitions = false
  private showConfirmation = false
  private completingGame = false
  private localCurrentPlayers = [] as GameLineupPositionPlayer[]

  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: true },
    { crumb: CrumbsEnum.GAME, isActive: false },
  ])

  private async mounted() {
    this.lineupCheck()
    await this.getRecognitions()
    this.localCurrentPlayers = cloneDeep(this.currentPlayers)
  }

  private playerRemoved() {
    this.localCurrentPlayers = cloneDeep(this.currentPlayers)
  }

  @Watch('localCurrentPlayers', { deep: true })
  private localCurrentPlayersChange() {
    const lineup = cloneDeep(this.currentGameLineupWithRotation)
    const segment = lineup.lineup.find((l) => {
      if (this.isFootball) {
        return l.segment === this.currentSegment && l.side === this.currentSide
      }
      return l.segment === this.currentSegment
    })
    if (segment) {
      segment.players = cloneDeep(this.localCurrentPlayers) //even though this is already a clone of the store, you still have to clone it again or you get a vuex mutation error.  Gotta love all the "magic"
      this.upsertLineup({ lineup })
    }
  }

  private async getRecognitions() {
    if (this.hasScheduledGames) {
      this.loadingRecognitions = true
      await this.fetchRecognitions(this.gameId)
      this.loadingRecognitions = false
    }
  }

  private get currentSegment(): number | null {
    const segment = this.currentGameLineupWithRotation.currentSegment
    return segment === null ? 1 : segment
  }

  private set currentSegment(segment: number | null) {
    const lineup = cloneDeep(this.currentGameLineupWithRotation)
    lineup.currentSegment = segment
    this.upsertLineup({ lineup })
    this.localCurrentPlayers = cloneDeep(this.currentPlayers)
  }

  private get currentSide(): string | null {
    if (!this.currentGameLineupWithRotation.currentSide)
      return this.currentGameLineupWithRotation.startingSide
    return this.currentGameLineupWithRotation.currentSide
  }

  private set currentSide(side: string | null) {
    const lineup = cloneDeep(this.currentGameLineupWithRotation)
    lineup.currentSide = side
    this.upsertLineup({ lineup })
    this.localCurrentPlayers = cloneDeep(this.currentPlayers)
  }

  private get pagingMaxLength(): number {
    if (this.programType) {
      if (this.isFootball) {
        return 4
      }
      return this.currentTeamNumberOfSegments
    }
    return 0
  }

  private get showLineup(): boolean {
    if (this.isFootball) return this.footballStartingSide ? true : false
    return true
  }

  private get programType(): UpwardProgramTypeID | undefined {
    const allprograms = cloneDeep(this.allProgramTypes)
    return allprograms.find((p) => p.upwardTypeID == this.currentTeam.typeProgramID)
  }

  private get isFootball(): boolean {
    return isFootball(this.programType?.upwardTypeID)
  }

  private get isBaseballOrSoftball(): boolean {
    return isBaseballOrSoftball(this.programType?.upwardTypeID)
  }

  private get showSideToggle(): boolean {
    return this.isFootball || this.isBaseballOrSoftball
  }

  private get sides() {
    return this.isBaseballOrSoftball
      ? [SidesEnum.BATTING, SidesEnum.FIELDING]
      : [SidesEnum.OFFFENSE, SidesEnum.DEFENSE]
  }

  get showStartingSideSelector() {
    return this.isFootball && this.currentSegment == 1
  }

  private get currentPlayers(): GameLineupPositionPlayer[] {
    const segment = this.currentGameLineupWithRotation.lineup.find((l) => {
      if (this.isFootball) {
        return l.segment === this.currentSegment && l.side === this.currentSide
      }
      return l.segment === this.currentSegment
    })
    if (
      this.isBaseballOrSoftball &&
      ((this.currentSide && this.currentSide == SidesEnum.BATTING) || !this.currentSide)
    ) {
      if (this.currentSide && this.currentSide == SidesEnum.BATTING) {
        //return the full list of team players ranked top to bottom, minus any inactive players
        return rankPlayers(
          differenceWith(
            this.currentTeam.players ?? ([] as TeamPlayerInfo[]),
            segment?.inactivePlayers || ([] as TeamPlayerInfo[]),
            function (o1, o2) {
              return o1['individualID'] === o2['individualID']
            }
          )
        ).map((x) => {
          return { ...x, positionText: '' }
        })
      } else {
        return [] as GameLineupPositionPlayer[]
      }
    } else {
      return rankPlayers(segment?.players || ([] as GameLineupPositionPlayer[]))
    }
  }

  private get footballStartingSide(): SidesEnum | null {
    return this.currentGameLineupWithRotation.startingSide
  }

  private set footballStartingSide(side: SidesEnum | null) {
    if (!side) return
    const lineup = cloneDeep(this.currentGameLineupWithRotation)
    lineup.startingSide = side
    this.setSegmentSides(side, lineup)
    if (!lineup.currentSide) lineup.currentSide = side
    this.upsertLineup({ lineup })
    this.localCurrentPlayers = cloneDeep(this.currentPlayers)
  }

  private setSegmentSides(startingSide: SidesEnum, lineup: GameLineupWithRotation): GameLineupWithRotation {
    // For football, alternate between offense and defense for each segment based
    // on the startingSide. If the number of players is exaclty twice the game format,
    // override the alternation so that the players don't always play the same side
    // (e.g. OD DO OD DO)
    const otherSide = this.otherSide(startingSide)

    if (this.overrideAlternation()) {
      lineup.lineup.forEach((l, i) => {
        l.side = this.overrideSide(i, startingSide, otherSide)
      })
    } else {
      lineup.lineup.forEach((l, i) => {
        l.side = this.alternatingSide(i, startingSide, otherSide)
      })
    }
    return lineup
  }

  alternatingSide(lineupSegment: number, startingSide: SidesEnum | null, otherSide: SidesEnum | null) {
    if (lineupSegment % 2 === 0) {
      return startingSide
    } else {
      return otherSide
    }
  }

  overrideSide(lineupSegment: number, startingSide: SidesEnum | null, otherSide: SidesEnum | null) {
    switch (lineupSegment) {
      case 0:
      case 3:
      case 4:
      case 7:
        return startingSide
      default:
        return otherSide
    }
  }
  overrideAlternation() {
    const playersPerSeg = this.currentGameLineupWithRotation.playersPerSegment
    const players = this.currentTeam.players ?? []
    //WF 535408 - edge case outlined in printed materials
    if ((players.length === 11 || players.length === 12) && playersPerSeg === 6) {
      return true
    } else {
      return players.length / playersPerSeg == 2
    }
  }

  private upperLimit() {
    if (this.currentGameLineupWithRotation.completed) {
      this.$router.push(`${this.gameId}/gamerecognition`)
    }
    this.showConfirmation = true
  }

  private async completeGame(response: boolean) {
    if (!response) this.showConfirmation = false
    if (response) {
      try {
        this.completingGame = true
        const nextGameId = this.nextGameId
        if (nextGameId) {
          await teamClient.setCurrentGame(this.currentTeam, nextGameId)
        }
        await this.fetchTeam()
        const lineup = cloneDeep(this.currentGameLineupWithRotation)
        lineup.completed = true
        this.upsertLineup({ lineup })
      } finally {
        this.completingGame = false
      }
      this.$router.push(`${this.gameId}/gamerecognition`)
    }
  }

  async fetchTeam() {
    const team = this.currentTeam
    return await this.fetchAndSetCurrentTeam({
      upwId: team?.upwardLeagueID ?? '',
      programTypeId: team?.typeProgramID ?? '',
      divisionId: team?.divisionID ?? 0,
      teamId: team?.teamID ?? 0,
    })
  }

  private get nextGameId(): number {
    const games = cloneDeep(this.currentTeam.games)
    const currentGame = games.find((g) => g.gameID === this.gameId)
    if (!currentGame) return 0
    const nextGame = games
      .sort((a, b) => a.roundNumber - b.roundNumber)
      .find((g) => g.roundNumber === currentGame?.roundNumber + 1)
    return nextGame?.gameID ?? 0
  }

  private otherSide(startingSide: string): SidesEnum | null {
    if (startingSide === SidesEnum.OFFFENSE) return SidesEnum.DEFENSE
    if (startingSide === SidesEnum.DEFENSE) return SidesEnum.OFFFENSE
    if (startingSide === SidesEnum.BATTING) return SidesEnum.FIELDING
    if (startingSide === SidesEnum.FIELDING) return SidesEnum.BATTING
    return null
  }

  private lineupCheck() {
    //If lineup does not exist for the current game, create it
    const noLineups = !this.allLineups.length
    const gameLineup = this.currentGameLineupWithRotation
    if (noLineups || gameLineup.gameId === 0) {
      this.createLineup()
    } else {
      this.localCurrentPlayers = cloneDeep(this.currentPlayers)
    }
  }

  private createLineup() {
    const allprograms = cloneDeep(this.allProgramTypes)
    const program = allprograms.find((p) => p.upwardTypeID === this.currentTeam.typeProgramID)
    if (!program) return

    const currentGame = this.currentTeam.games.find((g) => g.gameID === this.gameId)
    const round = getRound(currentGame, this.gameId)
    const isScheduledGame = getIsScheduled(currentGame)
    if (!this.currentTeam.players) throw Error('Cannot generate lineup with rotation without players')
    const players = rankPlayers(this.currentTeam.players)
    const divisionId = this.currentTeam.divisionID
    const teamId = this.currentTeam.teamID
    const playersPerSegment = gameFormatToPlayersPerSeg(this.currentTeam.typeGameFormatID)

    const lineup = generateLineupWithRotation(
      program,
      divisionId,
      teamId,
      this.gameId,
      round,
      players,
      playersPerSegment,
      isScheduledGame
    )
    if (this.currentGameLineupWithRotation.gameId !== 0) {
      //Gamelineup existed before. Preserve state
      lineup.currentSide = this.currentGameLineupWithRotation.currentSide
      lineup.currentSegment = this.currentGameLineupWithRotation.currentSegment
    }
    const startSide = this.currentGameLineupWithRotation.startingSide
    if (lineup) this.upsertLineup({ lineup }) //save lineup to localstorage using the store and vuex-persist
    this.footballStartingSide = startSide
  }
  @Action(lineupStore.actionNames.setSubstitutionPreference, { namespace: lineupStore.namespace })
  setSubstitutionPreference!: ({ preference }: { preference: SubstitutionPreference }) => void
  defaultSubstitution() {
    const seasonEndDate = addDays(new Date(this.currentProgram.seasonEndDate!), 1)
    const preference = SubstitutionPreferenceImpl.createNew(SubstitutionType.Default, seasonEndDate)
    this.setSubstitutionPreference({ preference })
  }
  get allowSubstitutionPreference(): boolean {
    const typeProgramID = this.currentTeam.typeProgramID ?? ''
    const ruleLevel = this.currentTeam.ruleLevel
    return typeProgramID == ProgramEnum.BASKETBALL && ruleLevel == 4
  }
}
