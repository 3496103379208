
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { cloneDeep } from 'lodash'

import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { CurrentGameLineupMixin } from '@/common/CurrentGameLineupMixin'
import { CurrentGameLineupCommonMixin } from '@/common/CurrentGameLineupCommonMixin'
import { ProgramEnum } from '@/common/programEnum'

import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import Loading from '@/elements/Loading.vue'
import SimpleMenu from '@/elements/SimpleMenu.vue'
import RegenerateLineup from '@/components/RegenerateLineup.vue'
import Incrementor from '@/elements/Incrementor.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import GameDayPlayer from '@/components/GameDayPlayer.vue'

import * as lineupStore from '@/store/lineup'
import * as teamStore from '@/store/team'
import * as upwardTypesStore from '@/store/upwardTypes'
import * as programStore from '@/store/programs'

import {
  GameLineup,
  getEmptyGameLineup,
  SubstitutionPreference,
  SubstitutionPreferenceImpl,
  SubstitutionType,
} from '@/models/Lineup'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'

import { generateLineup, getRound, getIsScheduled } from '@/services/lineupService'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import addDays from '@/utils/addDays'

@Component({
  components: {
    Breadcrumbs,
    Loading,
    SimpleMenu,
    RegenerateLineup,
    Incrementor,
    CheckboxInput,
    GameDayPlayer,
  },
})
export default class Lineup extends Mixins(CurrentGameLineupMixin, CurrentGameLineupCommonMixin) {
  @Getter(lineupStore.getterNames.lineups, { namespace: lineupStore.namespace })
  readonly allLineups!: GameLineup[]

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  readonly currentTeam!: DivisionTeamInfoExt

  @Getter(teamStore.getterNames.hasScheduledGames, { namespace: teamStore.namespace })
  private readonly hasScheduledGames!: boolean

  @Mutation(lineupStore.mutationNames.upsertLineup, { namespace: lineupStore.namespace })
  upsertLineup!: ({ lineup }: { lineup: GameLineup }) => void

  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  readonly currentProgram!: LeagueInfoCondensed

  breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: true },
    { crumb: CrumbsEnum.GAME, isActive: false },
  ])

  loadingRecognitions = false
  localCurrentLineup = getEmptyGameLineup()

  async mounted() {
    this.lineupCheck()
    await this.getRecognitions()
    this.localCurrentLineup = cloneDeep(this.currentGameLineup)
  }

  private async getRecognitions() {
    if (this.hasScheduledGames) {
      this.loadingRecognitions = true
      await this.fetchRecognitions(this.gameId)
      this.loadingRecognitions = false
    }
  }

  lineupCheck() {
    //If lineup does not exist for the current game, create it
    const noLineups = !this.allLineups.length
    const gameLineup = this.currentGameLineup
    if (noLineups || gameLineup.gameId === 0) {
      this.createLineup()
    }
  }

  createLineup() {
    const players = cloneDeep(this.currentTeam.players)
    if (!players) throw Error('Cannot generate lineup without players')

    const allprograms = cloneDeep(this.allProgramTypes)
    const program = allprograms.find((p) => p.upwardTypeID === this.currentTeam.typeProgramID)
    if (!program) throw Error('Cannot generate lineup without a program')

    const divisionId = this.currentTeam.divisionID
    const teamId = this.currentTeam.teamID
    const currentGame = this.currentTeam.games.find((g) => g.gameID === this.gameId)
    const round = getRound(currentGame, this.gameId)
    const isScheduledGame = getIsScheduled(currentGame)

    const lineup = generateLineup(program, divisionId, teamId, this.gameId, round, players, isScheduledGame)
    if (lineup) this.upsertLineup({ lineup })
  }

  @Watch('currentGameLineup', { deep: true })
  currentGameLineupChange() {
    this.localCurrentLineup = cloneDeep(this.currentGameLineup)
  }

  save() {
    const lineup = cloneDeep(this.localCurrentLineup)
    this.upsertLineup({ lineup })
  }

  get showFouls() {
    return this.currentTeam.typeProgramID !== ProgramEnum.VOLLEYBALL
  }

  get sortedPlayers() {
    return this.localCurrentLineup.players.sort((a, b) =>
      (a.player.rosterRanking || 'Z') > (b.player.rosterRanking || 'Z') ? 1 : -1
    )
  }

  @Action(lineupStore.actionNames.setSubstitutionPreference, { namespace: lineupStore.namespace })
  setSubstitutionPreference!: ({ preference }: { preference: SubstitutionPreference }) => void
  traditionalSubstitution() {
    const seasonEndDate = addDays(new Date(this.currentProgram.seasonEndDate!), 1)
    const preference = SubstitutionPreferenceImpl.createNew(SubstitutionType.Tranditional, seasonEndDate)
    this.setSubstitutionPreference({ preference })
  }
  get allowSubstitutionPreference(): boolean {
    const typeProgramID = this.currentTeam.typeProgramID ?? ''
    const ruleLevel = this.currentTeam.ruleLevel
    return typeProgramID == ProgramEnum.BASKETBALL && ruleLevel == 4
  }
}
