
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import LineupWithRotation from '@/components/LineupWithRotation.vue'
import Lineup from '@/components/Lineup.vue'
import * as teamStore from '@/store/team'
import * as lineupStore from '@/store/lineup'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { ProgramEnum } from '@/common/programEnum'
import { SubstitutionPreference, SubstitutionType } from '@/models/Lineup'

@Component({
  components: {
    LineupWithRotation,
    Lineup,
  },
})
export default class GamePlay extends Vue {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt
  @Getter(lineupStore.getterNames.substitutionPreference, { namespace: lineupStore.namespace })
  protected readonly substitutionPreference!: SubstitutionPreference

  private components = new Map([
    [ProgramEnum.FOOTBALL, 'LineupWithRotation'],
    [ProgramEnum.SOCCER, 'LineupWithRotation'],
    [ProgramEnum.BASKETBALL, 'LineupWithRotation'],
    ['BASKETBALL_LEVEL_4_DEFAULT', 'Lineup'],
    ['BASKETBALL_LEVEL_4_TRADITIONAL', 'LineupWithRotation'],
    [ProgramEnum.VOLLEYBALL, 'Lineup'],
    [ProgramEnum.BASEBALL, 'LineupWithRotation'],
    [ProgramEnum.SOFTBALL, 'LineupWithRotation'],
  ])

  get component(): string {
    const typeProgramID = this.currentTeam.typeProgramID ?? ''
    const ruleLevel = this.currentTeam.ruleLevel

    if (typeProgramID == ProgramEnum.BASKETBALL && ruleLevel == 4) {
      const substitutionType = this.substitutionPreference?.type ?? SubstitutionType.Default
      return this.components.get(`BASKETBALL_LEVEL_4_${substitutionType}`) ?? ''
    }
    return this.components.get(typeProgramID) ?? ''
  }
}
